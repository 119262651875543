import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-hot-toast';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import Button from '@/components/Button/Button';
import Input from '@/components/Input/Input';
import SearchDropdown from '@/components/SearchDropdown';
import { businessRegTypes, businessRegYears } from '@/utils/data.utils';
import { fetchCompliance, submitCompliance } from '@/services/complianceApi';
import { device } from '@/constants/breakpoints';
import FilePicker2 from '@/components/FilePicker2';

const schema = yup
  .object({
    websiteUrl: yup
      .string()
      .nullable()
      .notRequired()
      .test(
        'is-url-correct',
        'Enter correct url!',
        (value) =>
          !value ||
          /((https?):\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(
            value
          )
      ),
    registrationNumber: yup
      .string()
      .matches(/^[a-zA-Z0-9_-]*$/, 'Only letters, numbers, hyphens and underscores are allowed')
      .required('Registration number is required'),
    tin: yup
      .string()
      .matches(/^[a-zA-Z0-9_-]*$/, 'Only letters, numbers, hyphens and underscores are allowed')
      .required('TIN is required'),
    bvn: yup.string().required('BVN is required'),
    aboutBusiness: yup
      .string()
      .matches(
        /^[a-zA-Z0-9_\-,.\s]*$/,
        'Special characters are not allowed except for hyphens, commas and periods'
      )
      .required('Business description is required')
  })
  .required();

function Compliance() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [businessType, setBusinessType] = useState({});
  const [businessYear, setBusinessYear] = useState({});
  const [certificateDoc, setCertificateDoc] = useState(null);
  const [memorandumDoc, setMemorandumDoc] = useState(null);
  const [proofOfBusinessDoc, setProofOfBusinessDoc] = useState(null);
  const [completedStage, setCompletedStage] = useState(0);

  const compliance = useQuery({
    queryKey: ['compliance'],
    queryFn: fetchCompliance,
    onSuccess: (data) => {
      const findBusinessType = businessRegTypes.find(
        (item) => item.title === data?.data?.businessType
      );
      const findBusinessYear = businessRegYears.find(
        (item) => item.title === data?.data?.businessYear
      );
      setBusinessType(findBusinessType);
      setBusinessYear(findBusinessYear);
      setCertificateDoc(data?.data?.certificateUrl);
      setMemorandumDoc(data?.data?.memorandumUrl);
      setProofOfBusinessDoc(data?.data?.proofOfBusinessUrl);

      const keysToConsider = [
        'businessType',
        'businessYear',
        'certificateUrl',
        'memorandumUrl',
        'proofOfBusinessUrl',
        'registrationNumber',
        'tin',
        'bvn',
        'aboutBusiness'
      ];

      let nonNullCount = 0;

      keysToConsider.forEach((key) => {
        if (data?.data[key] !== null) {
          nonNullCount++;
        }
      });

      const totalKeys = keysToConsider.length;
      const submissionPercentage = Math.round((nonNullCount / totalKeys) * 100);

      setCompletedStage(submissionPercentage);
    }
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      websiteUrl: compliance?.data?.data?.websiteUrl?.trim() || '',
      registrationNumber: compliance?.data?.data?.registrationNumber?.trim(),
      tin: compliance?.data?.data?.tin?.trim(),
      bvn: compliance?.data?.data?.bvn?.trim(),
      aboutBusiness: compliance?.data?.data?.aboutBusiness?.trim()
    }
  });

  useEffect(() => {
    reset({
      websiteUrl: compliance?.data?.data?.websiteUrl || '',
      registrationNumber: compliance?.data?.data?.registrationNumber,
      tin: compliance?.data?.data?.tin,
      bvn: compliance?.data?.data?.bvn,
      aboutBusiness: compliance?.data?.data?.aboutBusiness
    });
  }, [compliance?.data?.data, reset]);

  const handleSubmitCompliance = useMutation(submitCompliance, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['compliance']);
      queryClient.invalidateQueries(['user']);
      queryClient.invalidateQueries(['dashboard-user']);
      toast.success('Compliance submitted successfully');
    },
    onError: (error) => {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message[0]);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  });

  const onSubmit = (data) => {
    const formData = {
      businessType: businessType?.title,
      businessYear: businessYear?.title,
      tin: data?.tin,
      bvn: data?.bvn,
      registrationNumber: data?.registrationNumber,
      aboutBusiness: data?.aboutBusiness,
      websiteUrl: data?.websiteUrl || null,
      certificateUrl: certificateDoc,
      memorandumUrl: memorandumDoc,
      proofOfBusinessUrl: proofOfBusinessDoc
    };
    if (formData.websiteUrl && !/^https?:\/\//i.test(formData.websiteUrl)) {
      formData.websiteUrl = 'https://' + formData.websiteUrl;
    }
    if (!businessType?.value) {
      toast.error('Please select business registration type');
      return;
    }
    if (!businessYear?.value) {
      toast.error('Please select business registration year');
      return;
    }
    handleSubmitCompliance.mutate(formData);
  };

  return (
    <ComplianceView>
      <TitleView>
        <div className="complaince-status">
          <h3>Compliance</h3>
          <div className="status-view">
            {compliance?.data?.data?.status === 'approved' ? (
              <div className="status verified">
                <span>Approved</span>
              </div>
            ) : compliance?.data?.data?.status === 'pending' ? (
              <div className="status pending">
                <span className="pending">Pending verification</span>
              </div>
            ) : compliance?.data?.data?.status === 'declined' ? (
              <div className="status pending">
                <span className="pending">Declined</span>
              </div>
            ) : null}
          </div>
        </div>
        {completedStage !== 100 && (
          <p>
            Get your business details verified to enable you access all the features on the
            platform, including bank wallet and payment features.{' '}
          </p>
        )}
      </TitleView>
      <div className="progress-title">
        <h3>Progress</h3>
        <p>{completedStage}% completed</p>
      </div>
      <ProgressView progress={completedStage}>
        <div className="progress-bar"></div>
      </ProgressView>
      {compliance?.isFetching ? (
        <div className="loader-view">
          <div className="loader">
            <Oval
              color="#92C22C"
              secondaryColor="#ddd"
              height={50}
              width={50}
              strokeWidth={4}
              ariaLabel="loading"
            />
          </div>
        </div>
      ) : (
        <form>
          {/* Business reg */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="website">Business registration type</label>
            </div>
            <SearchDropdown
              options={businessRegTypes}
              id="id"
              name="title"
              setValue={setBusinessType}
              value={businessType}
              placeholder="Select business registration type"
            />
          </div>

          {/* Business year */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="website">Business registration year</label>
            </div>
            <SearchDropdown
              options={businessRegYears}
              id="id"
              name="title"
              setValue={setBusinessYear}
              value={businessYear}
              placeholder="Select business registration year"
            />
          </div>

          {/* Certificate of Incorporation */}

          <div className="input-view">
            <div className="label-view">
              <label htmlFor="website">Certificate of Incorporation</label>
            </div>
            <FilePicker2 file={certificateDoc} setFile={setCertificateDoc} acceptsPDF />
          </div>

          {/* Memorandum and Articles of Association */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="website">Memorandum and Articles of Association/Status Report</label>
            </div>
            <FilePicker2 file={memorandumDoc} setFile={setMemorandumDoc} acceptsPDF />
          </div>

          {/* BVN */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="bvn">BVN Number</label>
            </div>
            <Input
              type="text"
              placeholder="Enter BVN"
              {...register('bvn')}
              error={errors?.bvn?.message}
            />
            {errors?.bvn && <span className="error-label">{errors?.bvn?.message}</span>}
          </div>

          {/* RC Number */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="registrationNumber">RC Number</label>
            </div>
            <Input
              type="text"
              placeholder="Enter RC Number "
              {...register('registrationNumber')}
              error={errors?.registrationNumber?.message}
            />
            {errors?.registrationNumber && (
              <span className="error-label">{errors?.registrationNumber?.message}</span>
            )}
          </div>

          {/* tin */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="tin">Tax Identification Number</label>
            </div>
            <Input
              type="text"
              placeholder="Enter Tax Identification Number"
              {...register('tin')}
              error={errors?.tin?.message}
            />
            {errors?.tin && <span className="error-label">{errors?.tin?.message}</span>}
          </div>

          {/* Utility bill */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="website"> Proof of Business Address (Utility bill)</label>
            </div>
            <FilePicker2 file={proofOfBusinessDoc} setFile={setProofOfBusinessDoc} acceptsPDF />
          </div>

          {/* Business description */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="aboutBusiness">Business description</label>
            </div>
            <Input
              type="text"
              placeholder="Enter business description"
              {...register('aboutBusiness')}
              error={errors?.aboutBusiness?.message}
            />
            {errors?.aboutBusiness && (
              <span className="error-label">{errors?.aboutBusiness?.message}</span>
            )}
          </div>

          {/* Website */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="website">Website (Optional)</label>
            </div>
            <Input type="text" placeholder="Enter website url" {...register('websiteUrl')} />
            {errors?.websiteUrl && (
              <span className="error-label">{errors?.websiteUrl?.message}</span>
            )}
          </div>

          {/* Button view */}
          {/* {completedStage === 100 && compliance?.data?.data?.isVerified ? null : (
            <div className="button-view">
              <Button
                type="button"
                styleType="primary"
                width="100%"
                onClick={handleSubmit(onSubmit)}>
                {handleSubmitCompliance.isLoading ? (
                  <Oval
                    color="#FFF"
                    secondaryColor="#ddd"
                    height={30}
                    width={30}
                    strokeWidth={4}
                    ariaLabel="loading"
                  />
                ) : (
                  'Save changes'
                )}
              </Button>
            </div>
          )} */}
          <div className="button-view">
            <Button type="button" styleType="primary" width="100%" onClick={handleSubmit(onSubmit)}>
              {handleSubmitCompliance.isLoading ? (
                <Oval
                  color="#FFF"
                  secondaryColor="#ddd"
                  height={30}
                  width={30}
                  strokeWidth={4}
                  ariaLabel="loading"
                />
              ) : (
                'Save changes'
              )}
            </Button>
          </div>
        </form>
      )}
    </ComplianceView>
  );
}

export default Compliance;

const ComplianceView = styled.div`
  width: 100%;
  padding: 0 60px;

  @media ${device.phone} {
    width: 100%;
    margin-top: 30px;
    padding: 0 0px;
  }

  .progress-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    h3 {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme?.colors?.secondary};
    }

    p {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme?.colors?.secondary};
    }
  }

  .loader-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  form {
    width: 75%;

    @media ${device.phone} {
      width: 100%;
      padding: 0;
    }

    .input-view {
      width: 100%;
      margin-bottom: 26px;

      .label-view {
        width: 100%;
        display: flex;
        margin-bottom: 8px;

        label {
          font-size: 1rem;
        }
      }

      .error-label {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.error};
        margin-top: 5px;
      }

      .input-info-view {
        width: 100%;
        display: flex;
        margin-top: 8px;

        span {
          font-size: 0.875rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }
    }

    .button-view {
      width: 230px;
      margin-top: 50px;

      @media ${device.phone} {
        width: 100%;
      }
    }
  }
`;

const ProgressView = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${({ theme }) => theme.colors?.secondary};
  border-radius: 6px;
  margin-bottom: 30px;

  .progress-bar {
    width: ${({ progress }) => `${progress}%`};
    height: 8px;
    background-color: ${({ theme }) => theme.colors?.primary};
    border-radius: 6px;
  }
`;

const TitleView = styled.div`
  width: 100%;
  margin-bottom: 30px;

  .complaince-status {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    h3 {
      font-size: 1.5rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }

    .status-view {
      width: 100%;
      display: flex;
      align-items: center;
      margin-left: 10px;

      .status {
      }

      .verified {
        padding: 5px 10px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colors?.success_200};

        span {
          font-size: 0.875rem;
          font-weight: 500;
          color: ${({ theme }) => theme.colors?.success};
        }
      }

      .pending {
        padding: 5px 10px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colors?.warning};

        span {
          font-size: 0.875rem;
          font-weight: 500;
          color: ${({ theme }) => theme.colors?.black};
        }
      }

      .denied {
        padding: 5px 10px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colors?.error_200};
        span {
          font-size: 0.875rem;
          font-weight: 500;
          color: ${({ theme }) => theme.colors?.white};
        }
      }
    }
  }

  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors?.activeColor};
    line-height: 22px;
    max-width: 90%;

    @media ${device.phone} {
      max-width: 100%;
    }

    a {
      color: ${({ theme }) => theme.colors?.primary};
      text-decoration: none;
      transition: all 0.3s ease-in-out;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { device } from '@/constants/breakpoints';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as BankIcon } from '@/assets/icons/bank2.icon.svg';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy.icon.svg';
import { numberWithCommas } from '@/utils/helpers';
import toast from 'react-hot-toast';
import TransferPolling from './TransferPolling';

function AccountInfoModal({ showModal, setShowModal, paymentData }) {
  const [showTransferConfirmation, setShowTransferConfirmation] = useState(false);
  const [timeLeft, setTimeLeft] = useState(1800);

  useEffect(() => {
    if (!showModal) return;

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setShowModal(false);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [showModal, setShowModal]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard');
  };

  const handleConfirmPayment = () => {
    // setShowModal(false);
    setShowTransferConfirmation(true);
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <ContentView>
                  <div className="header">
                    <button type="button" onClick={() => setShowModal(false)}>
                      <CloseIcon />
                    </button>
                  </div>

                  <div className="content">
                    <div className="icon">
                      <BankIcon />
                    </div>

                    <div className="company-name">
                      <p>Pay {paymentData?.accountName}</p>
                    </div>

                    <div className="line"></div>

                    <InfoView>
                      <p>
                        Kindly send the exact amount on display to the bank account details below
                      </p>
                      <div className="expiry-view">
                        <p>Account number expires in {formatTime(timeLeft)}</p>
                      </div>
                    </InfoView>

                    <div className="amount-view">
                      <span>Amount</span>
                      <div className="amount">
                        <p>
                          ₦{numberWithCommas(paymentData?.amount + paymentData?.charge || '00.00')}
                        </p>
                        <button
                          type="button"
                          onClick={() =>
                            copyToClipboard(paymentData?.amount + paymentData?.charge || '00.00')
                          }>
                          <CopyIcon />
                        </button>
                      </div>
                    </div>

                    <div className="details-view">
                      <div className="detail">
                        <span>Bank Name</span>
                        <div className="detail-value">
                          <p>{paymentData?.bankName} Bank</p>
                        </div>
                      </div>
                      <div className="detail">
                        <span>Account Name</span>
                        <div className="detail-value">
                          <p>{paymentData?.fullAccountName}</p>
                        </div>
                      </div>
                      <div className="detail">
                        <span>Account Number</span>
                        <div className="detail-value">
                          <p>{paymentData?.accountNumber}</p>
                          <button
                            type="button"
                            onClick={() => copyToClipboard(paymentData?.accountNumber)}>
                            <CopyIcon />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="button-view">
                      <button type="button" className="continue" onClick={handleConfirmPayment}>
                        I have made payment
                      </button>
                    </div>
                  </div>
                </ContentView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      {showTransferConfirmation && (
        <TransferPolling
          showPollingModal={showTransferConfirmation}
          setShowPollingModal={setShowTransferConfirmation}
          paymentData={paymentData}
          setShowAccountInfoModal={setShowModal}
        />
      )}
    </>
  );
}

export default AccountInfoModal;

AccountInfoModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired
};

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9011;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    min-width: 750px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      min-width: calc(100% - 32px);
      max-width: calc(100% - 32px);
      padding: 20px 16px;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 20px 0;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 30px;

    .icon {
      margin-bottom: 0.8rem;
      svg {
        width: 50px;
        height: 50px;
      }
    }

    .company-name {
      margin-bottom: 0.8rem;
      p {
        font-size: 1.1rem;
        font-weight: 500;
        color: ${(props) => props.theme.colors?.secondary};
      }
    }

    .line {
      width: 6rem;
      height: 1px;
      background-color: ${(props) => props.theme.colors?.info};
    }

    .amount-view {
      width: 100%;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 1rem;
        font-weight: 400;
        color: ${(props) => props.theme.colors?.secondary};
      }

      .amount {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 10px;

        p {
          font-size: 1.1rem;
          font-weight: 600;
          color: ${(props) => props.theme.colors?.secondary};
        }

        button {
          border: none;
          outline: none;
          cursor: pointer;
          margin-left: 10px;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .details-view {
      width: 100%;
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      @media ${device.phone} {
        flex-direction: column;
      }

      span {
        font-size: 1rem;
        font-weight: 400;
        color: ${(props) => props.theme.colors?.secondary};
      }

      .detail {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-top: 10px;

        @media ${device.phone} {
          margin-top: 20px;
        }

        .detail-value {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 8px;

          p {
            font-size: 1.1rem;
            font-weight: 600;
            color: ${(props) => props.theme.colors?.secondary};
            text-transform: capitalize;
          }

          button {
            border: none;
            outline: none;
            cursor: pointer;
            margin-left: 10px;

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }

    .button-view {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;

      .continue {
        width: 62%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${(props) => props.theme.colors?.primary};
        height: 64px;
        border-radius: 16px;
        color: ${(props) => props.theme.colors?.white};
        font-weight: 500;

        @media ${device.phone} {
          width: 100%;
        }
      }

      .cancel {
        width: 35%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${(props) => props.theme.colors?.white};
        height: 64px;
        border-radius: 16px;
        color: ${(props) => props.theme.colors?.primary};
        font-weight: 500;
        border: 1px solid ${(props) => props.theme.colors?.primary};
      }
    }
  }
`;

const InfoView = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors?.inputBackground};
  background-color: ${(props) => props.theme.colors?.inputBackground};
  padding: 20px 20px;
  border-radius: 6px;
  margin-top: 20px;

  p {
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    color: ${(props) => props.theme.colors?.secondary};
    line-height: 19px;
  }

  .expiry-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    p {
      font-size: 1rem;
      font-weight: 600;
      color: ${(props) => props.theme.colors?.secondary};
      line-height: 19px;
    }
  }
`;

/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-hot-toast';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import Button from '@/components/Button/Button';
import PhoneNumberInput from '@/components/PhoneInput';
import Input from '@/components/Input/Input';
import { fetchUser, updateUser } from '@/services/userApi';
import { device } from '@/constants/breakpoints';

const schema = yup
  .object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    phoneNumber: yup.string('Phone number is required').required('Phone number is required')
  })
  .required();

function EditProfile() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser,
    onSuccess: () => {},
    onError: () => {}
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: user?.data?.data?.firstName,
      lastName: user?.data?.data?.lastName,
      phoneNumber: user?.data?.data?.phoneNumber
    }
  });

  useEffect(() => {
    reset({
      firstName: user?.data?.data?.firstName,
      lastName: user?.data?.data?.lastName,
      phoneNumber: user?.data?.data?.phoneNumber
    });
  }, [user?.data?.data, reset]);

  const handleUpdateUser = useMutation(updateUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
      toast.success('Profile updated successfully!');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = (data) => {
    const formData = {
      firstName: data?.firstName,
      lastName: data?.lastName
    };

    const formData2 = {
      ...data,
      phoneNumber: data?.phoneNumber?.includes('+') ? data?.phoneNumber : `+${data?.phoneNumber}`
    };

    handleUpdateUser.mutate(formData2);

    // if (user?.data?.data?.phoneNumber) {
    //   handleUpdateUser.mutate(formData);
    // } else {
    //   handleUpdateUser.mutate(formData2);
    // }
  };

  return (
    <EditPProfileView>
      {user?.isFetching ? (
        <div className="loader-view">
          <div className="loader">
            <Oval
              color="#92C22C"
              secondaryColor="#ddd"
              height={50}
              width={50}
              strokeWidth={4}
              ariaLabel="loading"
            />
          </div>
        </div>
      ) : (
        <form>
          {/* First name */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="firstName">First name</label>
            </div>
            <Input
              type="text"
              placeholder="Enter first name."
              {...register('firstName')}
              error={errors?.firstName?.message}
            />
            {errors?.firstName && <span className="error-label">{errors?.firstName?.message}</span>}
          </div>

          {/* Last name */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="lastName">Last name</label>
            </div>
            <Input
              type="text"
              placeholder="Enter last name."
              {...register('lastName')}
              error={errors?.lastName?.message}
            />
            {errors?.lastName && <span className="error-label">{errors?.lastName?.message}</span>}
          </div>

          {/* Phone number */}
          {/* {!user?.data?.data?.phoneNumber ? (

          ) : (
            <div className="input-view">
              <div className="label-view">
                <label htmlFor="lastName">Phone number</label>
              </div>
              <Input type="text" value={user?.data?.data?.phoneNumber} />
            </div>
          )} */}

          <div className="input-view">
            <div className="label-view">
              <label htmlFor="phoneNumber">Phone numberr</label>
            </div>
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue={user?.data?.data?.phoneNumber}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <PhoneNumberInput
                  name={name}
                  onChange={onChange}
                  value={value}
                  onBlur={onBlur}
                  country="NG"
                  autoFormat
                  enableSearch
                  disableSearchIcon
                  enableAreaCodes
                  error={errors?.phoneNumber?.message}
                  className={`currency-input ${
                    errors?.phoneNumber && dirtyFields?.phoneNumber
                      ? ' has-error'
                      : dirtyFields?.phoneNumber
                        ? 'valid'
                        : ''
                  }`}
                />
              )}
            />
            {errors?.phoneNumber && (
              <span className="error-label">{errors?.phoneNumber?.message}</span>
            )}
          </div>

          {/* Button view */}
          <div className="button-view">
            <Button type="button" styleType="primary" width="100%" onClick={handleSubmit(onSubmit)}>
              {handleUpdateUser.isLoading ? (
                <Oval
                  color="#FFF"
                  secondaryColor="#ddd"
                  height={30}
                  width={30}
                  strokeWidth={4}
                  ariaLabel="loading"
                />
              ) : (
                'Save changes'
              )}
            </Button>
          </div>
        </form>
      )}
    </EditPProfileView>
  );
}

export default EditProfile;

const EditPProfileView = styled.div`
  width: 100%;

  @media ${device.phone} {
    width: 100%;
    margin-top: 30px;
  }

  .loader-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.phone} {
      width: 100%;
      margin-top: 20px;
    }

    .loader {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  form {
    width: 75%;
    padding: 0 60px;

    @media ${device.phone} {
      width: 100%;
      padding: 0;
    }

    .input-view {
      width: 100%;
      margin-bottom: 26px;

      .label-view {
        width: 100%;
        display: flex;
        margin-bottom: 8px;

        label {
          font-size: 1rem;
        }
      }

      .error-label {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.error};
        margin-top: 5px;
      }

      .input-info-view {
        width: 100%;
        display: flex;
        margin-top: 8px;

        span {
          font-size: 0.875rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }
    }

    .button-view {
      width: 230px;
      margin-top: 50px;

      @media ${device.phone} {
        width: 100%;
      }
    }
  }
`;
